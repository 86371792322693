<template>
    <div class="d-flex justify-content-end">
        <b-button variant="outline-primary" @click="navigateToUsersPage">
            تراجع
        </b-button>
    </div>
</template>
<script>
export default {
    methods: {
        navigateToUsersPage() {
            this.$router.push(`/admin/users/details/${this.$route.params.id}`);
        },
    },
};
</script>

<style scoped lang="scss"></style>
